import { initTranslation } from "../modules/i18n.js";
import initPage from "../modules/page";
import initGeoIPModal from "../modules/geoip-modal.js";
import { initCarousels } from "../modules/product-collection.js";
import { createProductImpressionObserver } from "../modules/analytics.js";
import { logGoogleRecommendationsAIEvent } from "../modules/analytics/google-analytics.js";
import { sendFacebookPixelEvent } from "../modules/analytics/facebook-pixel.js";
import { initAccordion } from "../modules/ui.js";
import {
  getKeyboardFocusableElements,
  disableBodyScroll,
  enableBodyScroll,
} from "../modules/utilities.js";
import "../../scss/brand.scss";

async function initBrandPage() {
  await initTranslation();
  initPage();
  initGeoIPModal();
  initCarousels();
  initPageSpecificAnalytics();
  initAccordion();

  // Intersection observer for analytics product impressions
  const observer = createProductImpressionObserver();
  document
    .querySelectorAll(".product-item")
    .forEach((item) => observer.observe(item));

  // Utility Menus
  document
    .querySelectorAll("#sort-by, #page-size")
    .forEach((select) =>
      select.addEventListener(
        "change",
        (e) => (window.location.href = e.target.value)
      )
    );

  const sidebar = document.querySelector(".sidebar");
  if (sidebar) {
    const backdrop = document.createElement("div");
    const keyboardElements = getKeyboardFocusableElements(sidebar);
    const firstFocusable = keyboardElements[0];

    backdrop.classList.add("sidebar-backdrop");

    const openSidebar = () => {
      disableBodyScroll();
      document.body.classList.add("sidebar-open");
      document.body.appendChild(backdrop);
      // put focus just after close button, so first tab selects first useable item instead
      if (firstFocusable) {
        firstFocusable.focus();
        firstFocusable.blur();
      }
    };

    const closeSidebar = () => {
      enableBodyScroll();
      document.body.classList.remove("sidebar-open");
      backdrop.parentNode.removeChild(backdrop);
    };

    const mobileFilters = document.querySelector(".mobile-filters");
    if (mobileFilters) {
      mobileFilters.addEventListener("click", openSidebar);
    }

    sidebar.querySelector(".btn-close").addEventListener("click", closeSidebar);
  }
}

function initPageSpecificAnalytics() {
  if (window.pageType === "warehouse") {
    let pageCategories = [];
    if (
      Array.isArray(window.warehouseCategoryNames) &&
      window.warehouseCategoryNames.length > 0
    )
      pageCategories = window.warehouseCategoryNames.reduce(
        (previous, current) => `${previous} > ${current}`
      );

    logGoogleRecommendationsAIEvent({
      eventType: "category-page-view",
      attributes: {
        userCountry: { text: [window.currentCountry] },
        userLanguage: { text: [window.currentLang] },
      },
      pageCategories: pageCategories,
    });
  } else if (window.pageType === "search") {
    if (!window.searchQuery) return;

    logGoogleRecommendationsAIEvent({
      eventType: "search",
      searchQuery: window.searchQuery,
      orderBy: window.searchSorting,
      attributes: {
        userCountry: { text: [window.currentCountry] },
        userLanguage: { text: [window.currentLang] },
      },
      productDetails: window.shortProductList,
    });

    sendFacebookPixelEvent("Search", {
      content_ids: [...document.querySelectorAll(".product-item")].map(
        (p) => p.dataset.productid
      ),
      search_string: window.searchQuery,
    });
  }
}

initBrandPage();
